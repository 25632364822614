<app-select-table-columns
  (displayedColumnsChange)="displayedColumnsChange($event)"
  [allColumns]="allColumns"
  [filterColumns]="filterColumns"
  *ngIf="extraColumns.length && allColumns.length"
></app-select-table-columns>

<ng-container *ngIf="selectable">
  <div class="d-flex justify-content-end align-items-center">
    <span class="mat-caption"
      >{{ selection.selected.length }} elements selected</span
    >
    <ng-template
      [ngTemplateOutlet]="selectActionsTemplate"
      [ngTemplateOutletContext]="{ $implicit: selection.selected }"
    >
    </ng-template>
  </div>
</ng-container>

<div class="table-responsive">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(element) : null"
          [checked]="selection.isSelected(element)"
          [disabled]="!canSelect(element)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container
      *ngFor="let mandatoryColumn of mandatoryColumns"
      [matColumnDef]="mandatoryColumn"
    >
      <th mat-header-cell *matHeaderCellDef>{{ mandatoryColumn }}</th>
      <td mat-cell *matCellDef="let element">
        <app-objectid-cell
          [id]="element.id"
          *ngIf="mandatoryColumn === 'id'"
        ></app-objectid-cell>

        <app-legal-tag-cell
          [legal]="element.legal"
          *ngIf="mandatoryColumn === 'legal'"
        ></app-legal-tag-cell>

        <ng-template
          *ngIf="mandatoryColumn !== 'id' && mandatoryColumn !== 'legal'"
          [ngTemplateOutlet]="columnContent"
          [ngTemplateOutletContext]="{
            element: element,
            column: mandatoryColumn
          }"
        ></ng-template>
      </td>
    </ng-container>

    <ng-container
      *ngFor="let extraCol of extraColumns"
      [matColumnDef]="extraCol"
    >
      <th mat-header-cell *matHeaderCellDef>{{ extraCol }}</th>
      <td mat-cell *matCellDef="let element">
        <ng-template
          [ngTemplateOutlet]="columnContent"
          [ngTemplateOutletContext]="{ element: element, column: extraCol }"
        ></ng-template>
      </td>
    </ng-container>

    <ng-container
      *ngFor="let templateColumn of templateColumns"
      [matColumnDef]="templateColumn.id"
    >
      <th mat-header-cell *matHeaderCellDef>{{ templateColumn.name }}</th>

      <td mat-cell *matCellDef="let element">
        <ng-template
          [ngTemplateOutlet]="templateColumn.templateRef"
          [ngTemplateOutletContext]="{ $implicit: element }"
        >
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let even = even"
      [ngClass]="{ strippedRow: even }"
    ></tr>
  </table>
</div>

<app-empty-state
  style="position: relative; top: -40px"
  icon="data_object"
  text="No object to display"
  *ngIf="dataSource.data.length === 0"
>
</app-empty-state>

<mat-paginator
  [pageSize]="10"
  [pageSizeOptions]="[5, 10, 25, 100]"
  [hidden]="dataSource.data.length === 0"
>
</mat-paginator>

<ng-template #columnContent let-element="element" let-column="column">
  <span *ngIf="isSimpleData(element, column)">
    {{ getExtraField(element, column) }}</span
  >
  <app-object-cell
    [object]="Helper.getFieldFromDottedString(element, column)"
    *ngIf="!isSimpleData(element, column)"
    [objectParent]="getObjectParentString(element, column)"
  >
  </app-object-cell>
</ng-template>
