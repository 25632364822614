<ng-container
  *ngFor="let element of filtersElements; let i = index; let last = last"
>
  <div class="d-flex align-items-center">
    <app-data-platform-filter-element
      [(filters)]="filtersElements[i]"
      [baseQuery]="baseQuery"
      (filtersChange)="filtersChanged()"
      class="flex-full"
    ></app-data-platform-filter-element>
    <mat-form-field appearance="outline" *ngIf="!last">
      <mat-select
        [(ngModel)]="followingOperators[i]"
        (ngModelChange)="operatorsChanged()"
      >
        <mat-label></mat-label>
        <mat-option *ngFor="let op of operators" [value]="op"
          >{{ op }}
        </mat-option>
      </mat-select>
      <mat-icon
        class="mat-icon-smaller"
        color="primary"
        matSuffix
        [matTooltip]="operatorTooltip"
        >info</mat-icon
      >
    </mat-form-field>

    <div class="mat-form-field-wrapper" *ngIf="last">
      <button mat-icon-button color="primary" (click)="addCondition()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="mat-form-field-wrapper" *ngIf="!last || i !== 0">
      <button mat-icon-button color="warn" (click)="removeCondition(i)">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </div>
  </div>
</ng-container>

<div>
  <mat-form-field appearance="outline">
    <mat-label>Limit</mat-label>
    <mat-select
      [(value)]="limit"
      (valueChange)="limitChanged()"
      [compareWith]="compareLimits"
    >
      <mat-option *ngFor="let l of limitList" [value]="l">{{ l }}</mat-option>
    </mat-select>
    <mat-icon matSuffix color="primary" [matTooltip]="tooltipLimit"
      >info</mat-icon
    >
  </mat-form-field>
</div>

<div class="d-flex actions justify-content-center">
  <button mat-raised-button color="primary" (click)="filterData.emit()">
    <mat-icon>filter_alt</mat-icon> Apply filters
  </button>
  <button mat-raised-button color="warn" (click)="clear.emit()">
    <mat-icon>filter_alt_off</mat-icon> Clear filters
  </button>
</div>
