<mat-card>
  <mat-accordion>
    <mat-expansion-panel style="margin: 20px 0">
      <mat-expansion-panel-header>
        <mat-panel-title> Modify Query FIlter </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-checkbox
        [(ngModel)]="isUserOwned"
        (ngModelChange)="userOwnedSearch()"
        [checked]="isUserOwned"
        color="primary"
        style="margin-bottom: 20px; margin-left: 5px"
        class="checkbox-container"
        >User Owned data
        <mat-icon
          class="mat-icon-smaller"
          color="primary"
          matTooltip="If checkbox is selected, the result only contains the records that
          the user owns. If unchecked, the result contains all records that
          the user is entitled to see. By default checkbox is disabled. It
          gets enabled only when data is available."
          >info</mat-icon
        >
      </mat-checkbox>
      <app-dataplatform-search-filters
        [baseQuery]="baseQuery"
        (filterData)="launchSearch()"
        [(followingOperators)]="followingOperators"
        [(limit)]="limit"
        [(filtersElements)]="filtersElements"
        (clear)="clearFilters()"
      ></app-dataplatform-search-filters>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="map-nav" *ngIf="canDisplayMap">
    <button mat-raised-button color="primary" (click)="navigateToMap()">
      <mat-icon>map</mat-icon>
      Navigate to Map
    </button>
  </div>

  <div class="mt-2"></div>
  <div
    class="d-flex justify-content-start mb-2 align-items-center"
    *ngIf="objectList.length < length"
  >
    <mat-icon color="warn">warning</mat-icon>

    <span class="mat-caption">
      Showing {{ objectList.length }} element{{
        objectList.length > 1 ? "s" : ""
      }}
      from a total of {{ length }} element{{ length > 1 ? "s" : "" }} in OSDU.
      Increase query filters limit to see more results, or refine your search.
    </span>
  </div>

  <div class="d-flex justify-content-center">
    <app-osdu-object-list
      [objectList]="objectList"
      [templateColumns]="templateColumns"
      [selectable]="selectable"
      [selectActionsTemplate]="selectActionsTemplate"
      [canSelect]="canSelect"
      [filters]="filtersElements"
    ></app-osdu-object-list>
  </div>
</mat-card>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.4)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  ><p style="color: white">Loading...</p></ngx-spinner
>
