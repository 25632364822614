<mat-toolbar>
  <div class="d-flex align-items-center flex-gap">
    <button mat-icon-button color="primary" (click)="toggleSidenav()">
      <mat-icon>{{ sidenavService.isOpened ? "menu_open" : "menu" }}</mat-icon>
    </button>

    <img
      src="/assets/images/admin_ui_full_logo_hd.png"
      alt="Logo"
      class="app-logo"
      (click)="goHome()"
    />

    <h5>{{ headerService.pageTitle }}</h5>
  </div>
  <span class="toolbar-spacer"></span>
  <app-user-menu></app-user-menu>
</mat-toolbar>
