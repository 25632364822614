<div
  class="d-flex align-items-center flex-gap"
  *ngIf="loggedIn"
  [matMenuTriggerFor]="menu"
  (menuOpened)="getConnectorVersion()"
>
  <img
    class="user-avatar pointer"
    [src]="userPhoto || '/assets/images/default_profile.png'"
    alt="User Avatar"
  />
</div>

<mat-menu #menu="matMenu" xPosition="before">
  <div
    class="d-flex flex-column justify-content-center align-items-center flex-gap ml-3 mr-3"
    (click)="$event.stopPropagation()"
  >
    <img
      class="user-avatar-menu"
      [src]="userPhoto || '/assets/images/default_profile.png'"
      alt="User Avatar"
    />

    <div class="d-flex justify-content-center align-items-center flex-column">
      <span class="mat-body-2">
        {{ username }}
      </span>

      <span class="mat-caption">
        {{ email }}
      </span>
    </div>

    <button mat-raised-button color="primary" (click)="logout()">
      <mat-icon>logout</mat-icon>Logout
    </button>
    <div class="align-items-center d-flex flex-column">
      <div class="d-flex align-items-center">
        <img
          src="/assets/images/admin_ui_logo.png"
          alt="AdminUi"
          class="app-logo"
        />
        <span class="mat-caption">
          &nbsp; v{{ applicationVersion }} {{ env }}</span
        >
      </div>
      <div class="d-flex align-items-center">
        <span class="mat-caption">
          Connector v<ng-container *ngIf="connectorVersion">{{
            connectorVersion
          }}</ng-container>
        </span>
        <mat-spinner [diameter]="12" *ngIf="!connectorVersion"></mat-spinner>
      </div>
    </div>
  </div>
</mat-menu>
